@import "~rsuite/dist/rsuite.min.css";

body {
	background-color: rgb(22, 22, 28);
	width: 100%;
	height: 100%;
	display: inline-block;
	overflow: hidden;
}

#root,
main {
	width: 100%;
	height: 100vh;
}

.nav-container {
	background: linear-gradient(rgb(94, 94, 94), transparent);
	background-color: rgb(48, 48, 48);
	position: fixed;
	width: 100%;
	height: 73px;
}

.nav-label-text {
	color: white;
	font-size: 18px;
	font-weight: 700;
	/* font-family: 'DinNormal !important'; */
}

.main-container {
	width: 100%;
	height: 100%;
	background-color: rgb(22, 22, 28);
	padding-top: 73px;
}

.nav-weath-clock-div {
	margin-Left: 0px;
	margin-right: 20px;
	display: flex;
	height: 73px;
	width: 15vw;
	justify-Content: flex-end;
}

.clock-div {
	width: 180px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.weather-div {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 30px;
	height: 100%;
	width: 100%;
}

.weather-image {
	width: 45px;
	height: 45px;
	margin-right: 12px;
}

.page-not-found-div {
	height: 100vh;
	display: grid;
	justify-content: center;
	align-content: center;
}

.page-not-found-text {
	color: white;
	font-size: 20px;
	font-weight: 700;
	/* font-family: 'DinNormal !important'; */
	text-align: center;
}

.login-div {
	display: grid;
	justify-content: center;
	align-content: center;
	padding-top: 10%;
}

.login-label {
	color: white;
	font-size: 18px;
	font-weight: 700;
}

.login-label-password-hint {
	color: white;
	width: 100%;
	font-size: 14px;
	font-weight: 700;
	text-Align: end;
}

.login-input {
	width: 100%;
	height: 50px;
	background-color: transparent;
	color: white;
	font-size: 18px;
	margin-top: 10px;
	padding-left: 10px;
	border: 1.4px solid rgb(151, 151, 151);
}

.login-input-error {
	width: 100%;
	height: 50px;
	background-color: transparent;
	color: white;
	/* font-family: 'DinNormal !important'; */
	font-size: 18px;
	margin-top: 10px;
	padding-left: 10px;
	border: 1.4px solid rgb(255, 84, 84);
}

.login-input:focus,
.login-input:focus-within {
	outline: none;
	width: 100%;
	height: 50px;
	background-color: transparent;
	color: white;
	/* font-family: 'DinNormal !important'; */
	font-size: 18px;
	margin-top: 10px;
	padding-left: 10px;
	border: 1.4px solid rgb(255, 143, 13);
}

.login-input-error-label {
	color: rgb(255, 84, 84);
	font-size: 14px;
	font-weight: 400;
	/* font-family: 'DinNormal !important'; */
}

.login-button {
	color: rgb(255, 143, 13);
	font-size: 34px;
	font-weight: 700;
	/* font-family: 'DinNormal !important'; */
	background-color: transparent;
	border: none;
	display: grid;
	margin-left: auto;
}

.dialog-title {
	color: rgb(51, 51, 51);
	font-size: 24px;
	font-weight: 700;
	width: 100%;
	text-align: center;
	/* font-family: 'DinNormal !important'; */
}

.dialog-content {
	color: rgb(102, 102, 102);
	font-size: 10px;
	font-weight: 400;
	width: 100%;
	text-align: center;
	/* font-family: 'DinNormal !important'; */
}

.dialog-content-dummy {
	width: 100%;
	border-bottom: 2px solid rgb(237, 237, 237);
}

.dialog-custom-button {
	color: rgb(248, 114, 54);
	font-size: 18px;
	font-weight: 700;
	width: 100%;
	text-align: center;
	/* font-family: 'DinNormal !important'; */
}

.div-box-container {
	background: linear-gradient(rgb(94, 94, 94), transparent);
	border-radius: 15px;
	background-color: rgb(48, 48, 48);
	display: flex;
	justify-content: center;
	align-content: center;
	align-self: center;
	width: 100%;
	height: 100%;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.div-box-container-dark {
	background: linear-gradient(rgb(48, 48, 48), transparent);
	border-radius: 8px;
	background-color: rgb(22, 22, 28);
	display: flex;
	width: 95%;
	height: 100%;
	margin-left: 10px;
	margin-Top: 15px;
	height: 110px;
	padding-left: 20px;
	padding-right: 20px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.div-box-container-dark-zone-detail {
	background: linear-gradient(rgb(48, 48, 48), transparent);
	border-radius: 8px;
	background-color: rgb(22, 22, 28);
	display: flex;
	width: 100%;
	height: 100%;
	padding: 20px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.div-box-container-dark-outerline {
	background: linear-gradient(rgb(48, 48, 48), transparent);
	border-radius: 8px;
	background-color: rgb(22, 22, 28);
	border: 2px solid rgb(255, 143, 13);
	display: flex;
	width: 95%;
	margin-left: 10px;
	margin-Top: 15px;
	height: 110px;
	padding-left: 20px;
	padding-right: 20px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.div-box-container-dark-highlight {
	background: linear-gradient(rgb(255, 143, 13), transparent);
	border-radius: 8px;
	background-color: rgb(255, 114, 54);
	display: flex;
	justify-content: center;
	align-content: center;
	align-self: center;
	width: 95%;
	margin-left: 10px;
	margin-Top: 15px;
	height: 110px;
	padding-left: 20px;
	padding-right: 20px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.div-box-container-dark-normal {
	background: linear-gradient(rgb(48, 48, 48), transparent);
	border-radius: 8px;
	background-color: rgb(22, 22, 28);
	display: flex;
	width: 100%;
	height: 100%;
	padding: 20px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.div-box-container-warning {
	background: linear-gradient(rgb(126, 2, 2), transparent);
	border-radius: 15px;
	background-color: rgb(159, 0, 0);
	border: 2px solid rgb(236, 59, 51);
	display: flex;
	justify-content: center;
	align-content: center;
	align-self: center;
	width: 100%;
	height: 100%;
}

.header-project-div {
	display: flex;
	width: 80vw;
	height: 73px;
}

.header-project-title-menu-div {
	display: flex;
	width: 24vw;
}

.normal-label {
	color: white;
	font-size: 18px;
	font-weight: 700;
	width: 100%;
	height: 100%;
	display: flex;
	align-content: center;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	/* font-family: 'DinNormal !important'; */
}

.hidden-overflow-label-div {
	overflow: hidden;
	text-Overflow: ellipsis;
	white-Space: nowrap;
	max-Width: calc(50%);
}

.hidden-overflow-clip-label-div {
	overflow: clip;
	text-Overflow: ellipsis;
	white-Space: nowrap;
	max-Width: calc(50%);
}

.project-container {
	background-color: white;
	border-radius: 10px;
	width: 40%;
	display: grid;
	margin-left: 30%;
}

.project-container-scroll {
	background-color: white;
	display: flex;
	flex-direction: column;
	height: 50vh;
	overflow-y: scroll;
}

.project-title {
	color: rgb(22, 22, 22);
	font-size: 20px;
	font-weight: 600;
	/* font-family: 'DinNormal !important'; */
	text-align: center;
	width: 100%;
	padding-top: 40px;
	padding-bottom: 30px;
}

.project-list-div {
	margin-left: 5%;
	margin-right: 5%;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	align-content: right;
	height: 100%;
	justify-content: center;
}

.project-list-div-selected {
	height: 100%;
	margin-left: 5%;
	margin-right: 5%;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 10px;
	display: flex;
	align-content: right;
	justify-content: center;
	background-color: rgb(240, 240, 240);
}

.project-list-item-label {
	color: rgb(102, 102, 102);
	font-size: 18px;
	height: 100%;
	font-weight: 600;
	/* font-family: 'DinNormal !important'; */
}

.project-list-item-pin-image {
	padding-right: 10px;
	size: 22px;
	padding-bottom: 10px;
}

.div-spacing-line {
	background-color: rgb(237, 237, 237);
	height: 2px;
	margin-left: 5%;
	margin-right: 5%;
}

.div-spacing-zone-line {
	width: 95%;
	background-Color: #FBFCFB;
	height: 1px;
	margin-Left: 20px;
}

.button-normal,
.button-normal:active {
	width: 100%;
	background-color: transparent;
	border: none;
	font-size: 14px;
	font-weight: 700;
	/* font-family: 'DinNormal !important'; */
	color: rgb(248, 114, 54);
	outline: 0;
	box-shadow: none;

}

.outer-button-normal,
.outer-button-normal:active {
	width: 100%;
	background: linear-gradient(rgb(94, 94, 94), transparent);
	background-color: rgb(48, 48, 48);
	border: 2px solid rgb(255, 143, 13);
	font-size: 14px;
	font-weight: 700;
	/* font-family: 'DinNormal !important'; */
	color: rgba(255, 143, 13, 1);
	outline: 0;
	box-shadow: none;
	border-radius: 5px;
}

.home-grid {
	width: 100%;
	height: 100vh;
	padding: 10px;
	padding-top: 15px;
	padding-right: 20px;
	padding-left: 20px;
	position: fixed;
}

.full-size-div {
	width: 100%;
	height: 100%;
}

.full-size-flex-div {
	width: 100%;
	height: 100%;
	display: flex;
}

.full-size-flex-row-div {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.home-top-section-div {
	display: flex;
	height: 22vh;
	margin-Bottom: 10px;
}

.home-top-total-woker-div {
	height: 100%;
	width: 100%;
	flex: 3;
	padding-right: 10px;
}

.home-top-woker-in-zone-div {
	height: 100%;
	width: 100%;
	flex: 5;
	margin-right: 10px;
	padding-right: 10px;
	background: linear-gradient(rgb(94, 94, 94), transparent);
	border-radius: 15px;
	background-color: rgb(48, 48, 48);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-self: center;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.home-top-alert-table-div {
	height: 100%;
	flex: 7;
	width: 100%;
}

.home-bottom-section-div {
	display: flex;
	height: 65vh;
	padding-bottom: 10px;
}

.home-bottom-section-zone-div {
	flex: 6;
	margin-right: 10px;
	background: linear-gradient(rgb(48, 48, 48), transparent);
	border-radius: 15px;
	background-color: rgb(22, 22, 28);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.home-bottom-section-zone-inner-div {
	display: flex;
	background: linear-gradient(rgb(94, 94, 94), transparent);
	border-radius: 15px;
	background-color: rgb(48, 48, 48);
	flex-direction: row;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
	margin: 10px 10px 10px;
}

.home-bottom-zone-div {
	min-width: 35%;
	flex: 1;
}

.home-bottom-site-map-div {
	max-width: 57%;
	width: 100%;
	height: 100%;
}

.scroll-div {
	overflow-Y: scroll;
	white-Space: nowrap;
	height: 55vh;
	width: 100%;
}

.paging-container-div {
	background-color: rgb(22, 22, 28);
	border-radius: 5px;
	height: 100%;
	width: 100%;
	padding-Top: 20px;
	padding-Left: 20px;
	padding-Right: 40px;
}

.paging-div {
	border-radius: 5px;
	padding-Left: 30px;
	padding-right: 30px;
	height: 42vh;
	width: 100%;
	overflow-y: scroll;
	white-Space: nowrap;
}

.paging-div-full-content {
	border-radius: 5px;
	padding-Left: 30px;
	padding-right: 30px;
	height: 35vh;
	width: 100%;
	overflow-y: auto;
	white-Space: nowrap;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 8px;
	height: 0px;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(217, 217, 217);
	border-radius: 20px;

}

.app-icon-button,
.app-icon-button:hover,
.app-icon-button:active {
	background-color: transparent;
}

.search-div {
	border: 1px solid white;
	border-radius: 5px;
	display: flex;
}

.search-div-searching {
	border: 1px solid rgb(255, 143, 13);
	border-radius: 5px;
	display: flex;
}

.search-input,
.search-input:focus,
.search-input:active {
	border: 0;
	box-shadow: none;
	outline: none;
	width: 100%;
	/* font-family: 'DinNormal !important'; */
	font-weight: 600;
	color: white;
	background-color: transparent;
}

.zone-list-header-div {
	width: 100%;
	height: 10%;
	padding-Top: 15px;
	margin-left: 10px;
	padding-right: 10px;
	display: flex;
}

.zone-list-div {
	display: flex;
	flex-Direction: column;
	width: 100%;
	padding-Left: 15px;
	padding-Right: 15px;
}

.circle-div {
	width: 16px;
	height: 16px;
	border-Radius: 50%;
	background-Color: rgb(255, 143, 13);
	margin-right: 10px;
	justify-Content: center;
	align-items: center;
	align-Content: center;
	text-Align: center;
}

.circle-div-white {
	width: 16px;
	height: 16px;
	border-Radius: 50%;
	background-Color: white;
	margin-right: 10px;
}

.page-link,
.page-link:hover,
.disabled>.page-link,
.page-link.disabled {
	background-color: transparent;
	color: rgb(151, 151, 151);
	margin: 10px;
	border: none;
	height: 32px;
}

.page-link:focus,
.page-link:active,
.active>.page-link,
.page-link.active {
	background-color: rgb(255, 143, 13);
	border: none;
	box-shadow: none;
	color: white;
	border-radius: 4px;
	height: 32px;
	margin: 10px;
	padding-bottom: 30px;
}

#arrow-page {
	color: orange;
	background-color: transparent;
}

.pop-up-div {
	position: absolute;
	top: 73px;
	width: 100%;
	height: 95%;
	color: white;
	background-Color: rgba(0, 0, 0, 0.4);
}

.pop-up-div-loading {
	position: absolute;
	top: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	color: white;
	background-Color: rgba(0, 0, 0, 0.4);
}

.setting-block-div {
	width: 294px;
	min-height: 100px;
	background-color: rgb(22, 22, 28);
	border-radius: 5px;
}

.logout-block-div {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.setting-language-div {
	display: flex;
	flex-direction: row;
	padding-left: 20px;
}

.text-icon-button {
	width: 100%;
	height: 60px;
	background-color: rgb(217, 217, 217);
	border-radius: 5px;
	background: linear-gradient(rgb(94, 94, 94), transparent);
	background-color: rgb(48, 48, 48);
	outline: none;
	border: none;
	display: flex;
	align-items: center;
	padding-left: 24px;
}

.cancel-button {
	width: 100%;
	/* font-family: 'DinNormal !important'; */
	font-weight: 700;
	font-size: 16px;
	color: rgb(151, 151, 151);
	background-color: transparent;
	text-align: center;
}

.confrim-button {
	width: 100%;
	/* font-family: 'DinNormal !important'; */
	font-weight: 700;
	font-size: 16px;
	color: rgb(248, 114, 54);
	background-color: transparent;
	text-align: center;
}

.drop-down-picker,
.drop-down-picker:hover,
.drop-down-picker:focus,
.drop-down-picker:active,
.drop-down-picker::after,
.drop-down-picker::before {
	width: 100%;
	height: 50px;
	border-radius: 5px;
	border: 1.4px solid rgb(151, 151, 151);
	outline: none;
	background-color: rgba(217, 217, 217, 0.2);
	color: white;
	padding-left: 16px;
	padding-right: 16px;
	border-bottom: none;
}

.drop-down-picker-item-select {
	color: rgb(255, 143, 13);
}

.check-box-div {
	display: flex;
}

.check-box-label {
	color: white;
	width: auto;
	font-size: 16px;
	font-weight: 600;
	/* font-family: 'DinNormal !important'; */
}

.check-box-tick-img {
	position: relative;
	top: -5px;
	right: 30px;
	width: 14px;
	height: 14px;
}

.checkbox-wrapper input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 4px;
	margin-left: 14px;
	margin-right: 14px;
	border: 1.4px solid rgb(151, 151, 151);
	outline: none;
	background-color: transparent;
	cursor: pointer;
}

.checkbox-wrapper input[type="checkbox"]:checked {
	background-color: rgb(255, 143, 13);
}

.textfield-filter,
.textfield-filter:focus,
.MuiOutlinedInput-input,
.label.Mui-focuse {
	width: 100%;
	height: 40px;
	background-color: transparent;
	color: black;
	/* font-family: 'DinNormal !importants'; */
	font-size: 10px;
	border: 1px solid rgb(102, 102, 102);
	/* padding: 34px; */
}

.pagination-div {
	width: 100%;
	height: 100%;
	justify-Content: center;
	margin-Top: 5px;
}

.img-icon-search {
	width: 18px;
	height: 18px;
	margin: 10px;
}

.zone-detail-table-header {
	padding-Left: 30px;
	padding-Right: 30px;
	display: flex;
	width: 100%;
}

.zone-detail-mute-switch {
	margin-Left: 20px;
}

.rs-toggle-presentation,
.rs-toggle-presentation:hover {
	background-color: rgba(94, 94, 94, 1);
}

.rs-toggle-checked .rs-toggle-presentation,
.rs-toggle-checked .rs-toggle-presentation:hover {
	background-color: rgba(255, 143, 13, 1);
}

.rs-picker,
.rs-picker-default,
.rs-picker-toggle.rs-btn,
.rs-picker-toggle-wrapper {
	height: 50px;
	width: 100%;
	display: flex;
	font-weight: 700;
	font-size: 10px;
	border: 1.4px solid rgb(151, 151, 151);
	outline: none;
	background-color: rgba(217, 217, 217, 0.2) !important;
	border-radius: 5px;
	color: white !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
	color: white !important;
	padding-top: 5px;
}

.rs-picker-select-menu-item {
	color: black
}

.rs-picker-menu.rs-picker-check-menu,
.rs-picker-menu.rs-picker-select-menu {
	max-height: 25%;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item:hover {
	color: rgb(255, 143, 13);
	background-color: rgba(217, 217, 217, 0.7);
}

.rs-picker-menu .rs-picker-search-bar {
	padding-left: 0px;
	padding-right: 0px;
	margin: 0px;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
	font-size: 10px;
}

svg {
	color: white !important;
	padding-top: 5px;
}

.site-map-pin-marker {
	border-radius: 50% 50% 50% 0;
	background-color: #3FC763;
	cursor: pointer;
	opacity: 90%;
}

.site-map-pin-control-zone-marker {
	border-radius: 10%;
	background-color: #3FC763;
	cursor: pointer;
	opacity: 90%;
}

.project-search-input,
.project-search-input:focus,
.project-search-input:hover {
	width: 80%;
	height: 50px;
	background-color: transparent;
	color: black;
	font-size: 18px;
	margin-top: 10px;
	padding-left: 10px;
	margin-right: 10px;
	margin-left: 10px;
	border: 0;
	box-shadow: 0;
	outline: 0;
}